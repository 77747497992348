import React, { useState } from "react"
import "./hero-img.scss"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { isMobile } from "react-device-detect"

const HeroImg = ({
  customClass,
  customSubtitle,
  children,
  subtitle,
  title,
  backgroundImage,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        backgroundImage: allFile(filter: { relativeDirectory: { eq: "bg" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 2480, quality: 65) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    `
  )

  const [isLoading, setIsLoading] = useState(true)

  const onImageLoad = event => {
    if (event === undefined) {
      setIsLoading(false)
    }
  }

  const sources = [
    {
      ...data.backgroundImage.edges.find(
        ({ node }) => node.relativePath === backgroundImage
      ).node.childImageSharp.fluid,
    },
  ]

  const classes = () => {
    const result = ["position-img"]
    if (customClass) {
      result.push(customClass)
    }

    if (isMobile) {
      result.push("position-img-mobile")
    }

    return result.join(" ")
  }

  return (
    <div className={isLoading === false ? "position-img-loaded" : ""}>
      <BackgroundImage
        onLoad={onImageLoad}
        Tag="section"
        className={classes()}
        fluid={sources}
        backgroundColor={`#121212`}
      >
        <h2 className={`hero-subtitle ${customSubtitle || ""}`}>{subtitle}</h2>
        <h1 className="hero-title">{title}</h1>
        {children}
      </BackgroundImage>
    </div>
  )
}

export default HeroImg
